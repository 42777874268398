import RestClient from "utils/RestClient"

const getMerchantById = (id) => RestClient.Get(`/merchant/${id}`);

const getMerchantCourses = (id) => RestClient.Get(`/merchant-course/${id}`);

const getMerchantLocations = (id) => RestClient.Get(`/merchant-location/${id}`);

const getMerchantSchedules = (id) => RestClient.Get(`/merchant-course-schedule/${id}`);

const getMerchantContacts = (id) => RestClient.Get(`/merchant-contact/${id}`);

const getMerchantSchedulesLoaction = (id, locationId) => RestClient.Get(`/merchant-course-schedule/${id}/${locationId}`);

const createMerchantLocation = (temp) => RestClient.Post(`/merchant-location`, temp);

const createMerchantCourse = (temp) => RestClient.Post(`/merchant-course`, temp);

const createMerchantContact = (temp) => RestClient.Post(`/merchant-contact`, temp);

const createMerchantWeeklySchedule = (temp) => RestClient.Post(`/merchant-course-schedule/weekly`, temp);

const createMerchantMonthlySchedule = (temp) => RestClient.Post(`/merchant-course-schedule/monthly`, temp);

const updateMerchant = (merchantId, temp) => RestClient.Put(`/merchant/${merchantId}`, temp);

const updateMerchantLocation = (locationId, temp) => RestClient.Put(`/merchant-location/${locationId}`, temp);

const updateMerchantCourse = (courseId, temp) => RestClient.Put(`/merchant-course/${courseId}`, temp);

const updateMerchantContact = (contactId, temp) => RestClient.Put(`/merchant-contact/${contactId}`, temp);

const updateMerchantWeeklySchedule = (scheduleId, temp) => RestClient.Put(`/merchant-course-schedule/weekly/${scheduleId}`, temp);

const updateMerchantMonthlySchedule = (scheduleId, temp) => RestClient.Put(`/merchant-course-schedule/monthly/${scheduleId}`, temp);

const deleteMerchantSchedule = (scheduleId) => RestClient.Delete(`/merchant-course-schedule/${scheduleId}`);

const deleteMerchantLocation = (id) => RestClient.Delete(`/merchant-location/${id}`);

const deleteMerchantCourse = (courseId) => RestClient.Delete(`/merchant-course/${courseId}`);


export default { getMerchantById, getMerchantLocations, getMerchantCourses, getMerchantSchedules, getMerchantContacts, getMerchantSchedulesLoaction, createMerchantCourse, createMerchantLocation, createMerchantContact, createMerchantWeeklySchedule, createMerchantMonthlySchedule, updateMerchant, updateMerchantLocation, updateMerchantCourse, updateMerchantContact, updateMerchantMonthlySchedule, updateMerchantWeeklySchedule, deleteMerchantSchedule, deleteMerchantLocation, deleteMerchantCourse }